'use client';

import Link from 'next/link';
import { useCid } from '@/components/context/data-context';
import { Button } from '../ui/button';
interface CidLinkProps {
  children: React.ReactNode;
  className?: string;
  href?: string;
}
export default function CidLink({
  children,
  className,
  href = '/'
}: CidLinkProps) {
  const cid = useCid();
  if (!cid) {
    return null;
  }
  return <Link href={`/${cid}${href}`} className={className} data-sentry-element="Link" data-sentry-component="CidLink" data-sentry-source-file="cid-link.tsx">
      <Button className="bg-aareal-aval" data-sentry-element="Button" data-sentry-source-file="cid-link.tsx">{children}</Button>
    </Link>;
}